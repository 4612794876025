import React from 'react';
import { Container, Nav, Navbar, Button, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './styles.module.scss'
import * as URLS from '../../Routes/urls'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../store/actions/authActions'


function TopNavbar() {

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const notification = useSelector(state => state.notification)
  const history = useHistory()

  return (
    <Navbar expand="lg" className={`navbar-dark ${styles.navbar}`}>
      <Container>
        {/* <NavLink to={URLS.HOME_PAGE}> */}
        <NavLink to={URLS.DASHBOARD_PAGE}>
          <Navbar.Brand>Theia</Navbar.Brand>
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <NavLink to={URLS.HOME_PAGE}>
              <Nav.Link href={URLS.HOME_PAGE}>About Theia</Nav.Link>
            </NavLink>
            <NavLink to={URLS.HOME_PAGE}>
              <Nav.Link href={URLS.HOME_PAGE}>How it Works</Nav.Link>
            </NavLink>
            <NavLink to={URLS.HOME_PAGE}>
              <Nav.Link href={URLS.HOME_PAGE}>Our Team</Nav.Link>
            </NavLink> */}
          </Nav>
          <Nav className="ml-auto">
            {auth.isAuthenticated ? (
              <>
                <NavLink to={URLS.DASHBOARD_PAGE}>
                  <Nav.Link href={URLS.DASHBOARD_PAGE}>Dashboard</Nav.Link>
                </NavLink>

                <NavLink to={URLS.NOTIFICATION_PAGE}>
                  <Nav.Link href={URLS.NOTIFICATION_PAGE}>
                    Notifications {notification.total_unread > 0 && <Badge bg="danger">{notification.total_unread}</Badge>}
                  </Nav.Link>
                </NavLink>

                <NavLink to={URLS.SETTINGS_PAGE}>
                  <Nav.Link href={URLS.SETTINGS_PAGE}>Settings</Nav.Link>
                </NavLink>
                <Nav.Link>
                  <li onClick={() => dispatch(logout(history))}>Logout</li>
                </Nav.Link>
              </>
            ) : (
              <>
                <NavLink to={URLS.REGISTRATION_PAGE}>
                  <Button className="w-100 mb-2 mb-md-0" variant="warning">Register</Button>
                </NavLink>
                <NavLink to={URLS.LOGIN_PAGE}>
                  <Button className="w-100 ms-md-2" variant='dark'>Login</Button>
                </NavLink>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavbar;