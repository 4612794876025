
export function getHeaders(additional) {
  const userToken = localStorage.getItem(process.env.REACT_APP_USER_TOKEN_KEY_NAME)
  const timezoneOffset = new Date().getTimezoneOffset()

  let headers = {
    ...additional,
    "X-Api-Timezone-Offset": timezoneOffset
  }

  if (userToken) {
    headers["Authorization"] = `Token ${userToken}`
  }
  return headers
}


export function createUUID() {
  // used to generate a random UUID (for key props)
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
    return v.toString(16);
  });
}

export const setPageTitle = (title) => {
  const siteName = process.env.REACT_APP_SITE_TITLE;
  if (title) {
    document.title = title + " | " + siteName
  } else {
    document.title = siteName
  }
  return true
}

export const arrayGroupBy = (array, keyFunc, sortFunc) => {
  const groups = {};
  
  array.forEach(item => {
    const key = keyFunc(item);
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
  });

  // Sort each group
  for (const key in groups) {
    groups[key].sort(sortFunc);
  }
  
  // Convert to array of key-value pairs
  const resultList = Object.entries(groups);

  return resultList;
}

