import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { ADMIN_DASHBOARD_PAGE, CLINICIAN_DASHBOARD_PAGE, PATIENT_DASHBOARD_PAGE } from '../../Routes/urls';
import { isAdministrator, isClinician } from '../../utils/authUtils';
import classes from './styles.module.scss'
import CommenLoader from '../../components/CommenLoader';


const DashboardPage = () => {
  const auth = useSelector(state => state.auth)
  const history = useHistory()

  useEffect(() => {
    if (!auth.loading) {

      if (auth.user.user_type) {
        if (isAdministrator(auth.user)) {
          history.push(ADMIN_DASHBOARD_PAGE)
        }
        else if (isClinician(auth.user)) {
          history.push(CLINICIAN_DASHBOARD_PAGE)
        }
        else {
          history.push(PATIENT_DASHBOARD_PAGE)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth])

  return (
    <div className={classes.mainDashboard}>
      <CommenLoader />
    </div>
  )

}


export default DashboardPage