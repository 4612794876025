import React, { useEffect, useState } from 'react'
import classes from '../styles.module.scss'
import moment from 'moment'
import Calendar from '../PatientDashboard/DailyQuestion/Calendar/Calendar'
import { loadTestResults, loadUserDailySavedAnswersStatus } from './api-service'
import PopUp from '../../../components/Popup/Popup'
import DailyAnswers from './DailyAnswers'
import InitialAnswers from './InitalAnswers/InitalAnswers'
import HormoneResult from './HormoneResult/HormoneResult'
import CommenLoader from '../../../components/CommenLoader'
import Button from '../../../components/FormElements/Button'


const PatientDetails = ({ data }) => {
  const [dateCellOpened, setDateCellOpened] = useState(null)
  const [initialAnswersOpened, setInitialAnswersOpened] = useState(false)
  const [eventList, setEventList] = useState([])
  const [dateRange, setDateRange] = useState({})
  const [testResults, setTestResults] = useState([])
  const [loadingTestResults, setLoadingTestResults] = useState(false)

  const loadSavedAnswersStatus = ({ start, end }) => {
    loadUserDailySavedAnswersStatus(setEventList,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      data.id
    )
  }

  const onPopupClose = () => {
    setDateCellOpened(null)
  }

  useEffect(() => {
    if (dateRange?.start) {
      loadSavedAnswersStatus(dateRange)
    }

    // eslint-disable-next-line
  }, [dateRange?.start, dateRange?.end])

  useEffect(() => {
    if (data.id) {
      loadTestResults(data.id, setTestResults, setLoadingTestResults)
    }
  }, [data.id])



  return (
    <div className={classes.patientDetails}>
      <h2>Patient Details (PID: {data.id})</h2>
      <div style={{ marginBottom: "10px" }}>
        <table className={classes.patientDetails_table}>
          <tbody>
            <tr>
              <th>Patient ID:</th>
              <td>{data.id}</td>
              <th>Date of birth:</th>
              <td>{data.date_of_birth || "-"}</td>
            </tr>
            <tr>
              <th>Full Name:</th>
              <td>{data.name || "-"}</td>
              <th>Postal Address:</th>
              <td>{data.postal_address || "-"}</td>
            </tr>
            <tr>
              <th>Email:</th>
              <td>{data.email || "-"}</td>
              <th>City:</th>
              <td>{data.city || "-"}</td>
            </tr>
            <tr>
              <th>Mobile:</th>
              <td>{data.mobile || "-"}</td>
              <th>Ethnicity:</th>
              <td>{data.ethnicity || "-"}</td>
            </tr>
            <tr>
              <th>Last Login:</th>
              <td>{data.last_login ? moment(data.last_login).fromNow() : "-"}</td>
              <th>Country:</th>
              <td>{data.country || "-"}</td>
            </tr>
            <tr>
              <th>Height:</th>
              <td>{data.height || "-"}</td>
              <td colSpan={2}>
                <Button
                  onClick={() => setInitialAnswersOpened(true)}
                  text={"View Initial Questions' Answers"}
                />
              </td>
            </tr>

          </tbody>
        </table>
      </div>

      <h2>Hormone Test Result</h2>
      {loadingTestResults ? (
        <CommenLoader />
      ) : (
        <HormoneResult data={testResults} />
      )}

      <h2>Submissions</h2>
      <div style={{ marginBottom: "50px" }}>
        <Calendar
          eventList={eventList}
          handleDateSelect={(date) => setDateCellOpened(date)}
          onDateRangeChange={setDateRange}
        />
        {dateCellOpened && (
          <PopUp
            title={moment(dateCellOpened).format("DD MMM, YYYY")}
            onClose={onPopupClose}
          >
            <DailyAnswers userId={data.id} date={dateCellOpened} />
          </PopUp>
        )}
        {initialAnswersOpened && (
          <PopUp
            title={"Initial Questions' Answers"}
            onClose={() => setInitialAnswersOpened(false)}
          >
            <InitialAnswers userId={data.id} />
          </PopUp>
        )}
      </div>
    </div>
  )

}


export default PatientDetails