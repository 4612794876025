import React, { useEffect } from 'react'
import moment from 'moment'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './calendar.css'

const localizer = momentLocalizer(moment)

const Calendar = ({ eventList, handleDateSelect, onDateRangeChange, disableFutureDate }) => {
  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.color ? event.color : 'rgb(126, 78, 160)';

    return {
      style: {
        backgroundColor: backgroundColor,
        fontSize: "16px",
      },
    };
  };
  
  const dayStyleGetter = (date, color) => {
    if (disableFutureDate ) {
      const backgroundColor = date > moment() ? "#ddd" : 'null';

      return {
        style: {
          backgroundColor: backgroundColor,
          fontSize: "16px",
        },
      };
    }

    return {
      style: {
        fontSize: "16px",
      },
    };
  };

  const onNavigate = (date, view) => {
    let start, end;
  
    start = moment(date).startOf('month').startOf('week')
    end = moment(date).endOf('month').endOf('week')
    return onDateRangeChange({ start, end });
  }

  useEffect(() => {
    // initially get the date range
    onNavigate(moment())

    // eslint-disable-next-line
  }, []);

  return (
    <BigCalendar
      localizer={localizer}
      events={eventList}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 600 }}
      views={["month"]}
      eventPropGetter={eventStyleGetter}
      selectable
      onSelectSlot={({start, action}) => action === 'click' && handleDateSelect(start)}
      onSelectEvent={(event) => handleDateSelect(event.start)}
      onNavigate={onNavigate}
      dayPropGetter={dayStyleGetter}
    />
  )

}


export default Calendar
