import React, { useEffect, useState } from 'react'
import { createUUID, getHeaders } from '../../../utils'
import classes from '../styles.module.scss'
import axios from '../../../utils/axios'
import CommenLoader from '../../../components/CommenLoader'
import Button from '../../../components/FormElements/Button'
import moment from 'moment'
import ErrorMessage from '../../../components/FormElements/ErrorMessage'
import { confirmAlert } from "react-confirm-alert";
import { showErrorMessage, showSuccessMessage } from '../../../utils/toast'


const ClinicianTable = ({ onCreate }) => {

  const [clinicians, setClinicians] = useState([])
  const [cliniciansLoading, setCliniciansLoading] = useState(false)
  const [cliniciansLoadError, setCliniciansLoadError] = useState(false)

  useEffect(() => {
    loadClincians()
  }, [])

  const loadClincians = () => {
    // load the clinicians accounts
    setCliniciansLoading(true)

    axios.get('/auth/clinicians/', { headers: getHeaders() })
      .then(({ data }) => {
        setClinicians(data)
        setCliniciansLoading(false)
        setCliniciansLoadError(false)
      })
      .catch(error => {
        setCliniciansLoading(false)
        setCliniciansLoadError(true)
      })
  }

  const deleteClinician = (clinicianId) => {
    confirmAlert({
      title: "Are you sure?",
      message: "This clinician account will be deleted permanently and there's no going back. Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios.delete(`/auth/clinician/${clinicianId}`, { headers: getHeaders() })
              .then(res => {
                showSuccessMessage(`Clincian with id - ${clinicianId} was deleted!`)

                // load the clinicians data again.
                loadClincians()
              })
              .catch(error => {
                showErrorMessage()
              })
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  }

  return (
    <>
      <Button
        text={"Create new Clinician"}
        onClick={onCreate}
      />
      <div className={classes.mainContent_tableContainer}>
        <table className={classes.mainContent_table}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Last Login</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cliniciansLoading ? (
              <tr><td colSpan={5}><CommenLoader height={120} size={50} /></td></tr>
            ) : (
              <>
                {cliniciansLoadError ? (
                  <tr><td colSpan={5}>Something went wrong! Please try again later</td></tr>
                ) : (
                  <>
                    {clinicians.length ? (
                      <>
                        {clinicians.map(clinician => (
                          <tr key={createUUID()}>
                            <td>{clinician.id}</td>
                            <td>{clinician.name}</td>
                            <td>{clinician.email}</td>
                            <td>{clinician.last_login ? moment(clinician.last_login).fromNow() : "-"}</td>
                            <td>
                              <div onClick={() => deleteClinician(clinician.id)} className={classes.patientDetailsLink}>
                                Delete
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr><td colSpan={5}><ErrorMessage message={"No clinician found!"} /></td></tr>
                    )}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  )

}


export default ClinicianTable

