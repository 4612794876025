import React, { useState } from 'react'
import Navbar from '../../../components/layouts/Navbar'
import { setPageTitle } from '../../../utils'
import classes from '../styles.module.scss'
import CreatePatient from './CreatePatient'
import PatientTable from './PatientTable'


const ClinicianDashboardPage = () => {

  setPageTitle("Clinician Dashboard")
  const [createPatient, setCreatePatient] = useState(false)

  return (
    <div className={classes.dashboardContainer}>
      <Navbar />
      <div className={classes.mainContent}>
      {createPatient ? (
          <CreatePatient onClose={() => setCreatePatient(false)} />
        ) : (
          <PatientTable onCreate={() => setCreatePatient(true)} />
        )}
      </div>
    </div>
  )

}


export default ClinicianDashboardPage