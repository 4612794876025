import React, { useState } from 'react'
import Section from './Section'
import { arrayGroupBy } from '../../../../utils'


const InitialQuestions = ({ savedAnswers, questions, setSavedAnswers }) => {

  const [currentStep, setCurrentStep] = useState(1)
  let totalNumQuestions = 0;

  return (
    <>
      {arrayGroupBy(
        questions, (question) => question.category,
        (a, b) => a.category.localeCompare(b.category)).map(([key, group], index) => {
          totalNumQuestions += group.length;

          return (
            <div key={index}>
              {index + 1 === currentStep && (
                <Section
                  questions={group}
                  answers={savedAnswers}
                  setAnswers={setSavedAnswers}
                  sectionTitle={key}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  totalNumQuestions={totalNumQuestions}
                />
              )}
            </div>
          )
        }

        )}
    </>
  )

}


export default InitialQuestions