import React from "react";
import classes from "./styles.module.scss";
import { setPageTitle } from "../../utils";
import { useSelector } from "react-redux";
import Button from "../../components/FormElements/Button";

const NotFound = ({ history }) => {
  setPageTitle("Page not found");

  const isNightMode = useSelector((state) => state?.theme?.nightMode);

  return (
    <div
      className={`${classes.container} ${
        isNightMode && classes.container_nightMode
      }`}
    >
      <div className={classes.container_box}>
        <h1>404</h1>
        <p>Page not found</p>
        <Button text={"Go Home"} onClick={() => history.push("/")} />
      </div>
    </div>
  );
};

export default NotFound;
