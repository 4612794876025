import React from 'react'
import Checkbox from './CheckboxInput'


const CheckboxGroup = ({ question, onChange, answers }) => {

  const handleChange = (e) => {
    let updatedValues;
    if (e.target.checked) {
      updatedValues = [...(answers[question.id]?.split(',') || []), e.target.value]
    } else {
      updatedValues = answers[question.id]?.split(',')?.filter((item) => item !== e.target.value)
    }

    onChange({
      target: {
        name: e.target.name,
        value: updatedValues.join(','),
      }
    })
  }

  return (
    <>
      {question.options.map(option => (
        <Checkbox
          label={option.option}
          key={option.id}
          name={question.id}
          onChange={handleChange}
          checked={answers[question.id]?.split(',')?.includes(option.option)}
          value={option.option}
        />
      ))
      }
    </>
  )

}


export default CheckboxGroup