// auth action types
// user
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";
export const USER_REGISTERED = "USER_REGISTERED";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
export const AUTH_LOADING = "AUTH_LOADING"; // for all kind of auth stuffs (login, register, reset password bla bla bla)
export const USER_INFO_LOADED = "USER_INFO_LOADED";
export const USER_INFO_LOAD_ERROR = "USER_INFO_LOAD_ERROR";
export const TOGGLE_FORGOT_PASSWORD = "TOGGLE_FORGOT_PASSWORD";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_STATUS = "RESET_PASSWORD_STATUS";
export const CHANGE_PASSWORD_ERRORS = "CHANGE_PASSWORD_ERRORS";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";

export const PROFILE_UPDATE_ERRORS = "PROFILE_UPDATE_ERRORS";
export const PROFILE_UPDATED = "PROFILE_UPDATED";

export const NOTIFICATION_LOADED = "NOTIFICATION_LOADED"
