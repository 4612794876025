import React from 'react'
import RadioInput from './RadioInput'


const RadioGroup = ({ question, onChange, answers }) => {

  return (
    <>
      {question.options.map(option => (
        <RadioInput
          label={option.option}
          key={option.id}
          name={question.id}
          onChange={onChange}
          checked={answers[question.id] === option.option}
          value={option.option}
        />
      ))
      }
    </>
  )

}


export default RadioGroup