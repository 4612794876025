import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Calendar from './Calendar/Calendar'
import PopUp from '../../../../components/Popup/Popup'
import { loadDailySavedAnswersStatus, loadQuestions } from '../api-service'
import Questions from './Questions'


const DailyQuestions = () => {

  const [dateCellOpened, setDateCellOpened] = useState(null)
  const [questions, setQuestions] = useState([])
  const [eventList, setEventList] = useState([])
  const [dateRange, setDateRange] = useState({})

  const handleDateSelect = date => {
    if (date <= moment()) {
      setDateCellOpened(date)
    }
  }

  const loadSavedAnswersStatus = ({ start, end }) => {
    loadDailySavedAnswersStatus(setEventList,
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD")
    )
  }

  const onPopupClose = () => {
    loadSavedAnswersStatus(dateRange)
    setDateCellOpened(null)
  }

  useEffect(() => {
    if (dateRange?.start) {
      loadSavedAnswersStatus(dateRange)
    }

    // eslint-disable-next-line
  }, [dateRange?.start, dateRange?.end])


  useEffect(() => {
    // load daily questions just once
    loadQuestions("Daily Question", setQuestions)

    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Calendar
        eventList={eventList}
        handleDateSelect={handleDateSelect}
        onDateRangeChange={setDateRange}
        disableFutureDate={true}
      />
      {dateCellOpened && (
        <PopUp
          title={moment(dateCellOpened).format("DD MMM, YYYY")}
          onClose={onPopupClose}
        >
          <Questions
            date={dateCellOpened}
            questions={questions}
            onClose={onPopupClose}
          />
        </PopUp>
      )}
    </div>
  )

}


export default DailyQuestions