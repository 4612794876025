import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, forgotPassword } from "../../store/actions/authActions";
import {
  TOGGLE_FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
} from "../../store/actions/actionTypes";
import { withRouter } from "react-router-dom";
import classes from "./authStyles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { setPageTitle } from "../../utils";
import ErrorMessage from "../../components/FormElements/ErrorMessage";
import Button from "../../components/FormElements/Button";
import Navbar from "../../components/layouts/Navbar";
import dts_jpeg from '../../assets/images/DTS_Daniel_Faro_Practice.jpg'



const SignIn = ({ history }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);
  const [email, setEmail] = useState("");

  setPageTitle("Login");

  
  const submitHandler = (e) => {
    e.preventDefault();
    setDisable(true);

    dispatch(login({ username, password }, history));
  };

  const forgotPasswordHandler = (e) => {
    e.preventDefault();
    setDisable(true);
    dispatch(
      forgotPassword(email, () => {
        setEmail("");
      })
    );
  };

  useEffect(() => {
    if (!auth.loading) {
      setDisable(false);
    }
  }, [auth]);

  return (
    <>
      <Navbar />
      {/* <img src={dts_jpeg} alt="dts_jpeg" /> */}
      <div className={classes.authContainer}>
        <div className={classes.authContainer_FormContainer}>
          <h2 className={classes.authContainer_FormContainer__header}>
            Login to your account
          </h2>
          <form onSubmit={submitHandler}>
            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Email
              </label>
              <input
                type="email"
                className={classes.inputContainer_input}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required={true}
              />
            </div>
            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Your password
              </label>
              <input
                type="password"
                className={classes.inputContainer_input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={true}
              />
              <p
                className={classes.inputContainer_smallBtn}
                onClick={() => {
                  dispatch({ type: TOGGLE_FORGOT_PASSWORD, payload: true });
                  dispatch({
                    type: FORGOT_PASSWORD_FAIL,
                    payload: false,
                  });
                }}
              >
                Forgot Password?
              </p>
            </div>
            <Button
              className={classes.authContainer_submitBtn}
              disabled={disable}
              type="submit"
              text={auth.loading ? "Please wait..." : "Login"}
            />
            {auth.loginErrors?.error && (
              <ErrorMessage message={auth.loginErrors.error} />
            )}
          </form>
        </div>
      </div>

      {auth.isForgotPOpened && (
        <div className={classes.authBody_popUp}>
          <div className={classes.authBody_popUp__box}>
            <FontAwesomeIcon
              icon={faTimes}
              className={classes.authBody_popUp__closeIcon}
              onClick={() => {
                dispatch({ type: TOGGLE_FORGOT_PASSWORD, payload: false });
                dispatch({ type: FORGOT_PASSWORD_FAIL, payload: false });
                setEmail("");
              }}
            />
            {auth.isResetEmailSent ? (
              <p className={classes.authBody_popUp__successText}>
                Password reset e-mail has been sent.
              </p>
            ) : (
              <>
                <h2 className={classes.authBody_popUp__header}>
                  Forgot Password?
                </h2>
                <p className={classes.authBody_popUp__subText}>
                  Don't worry, a password reset link will be sent to your
                  registered email address.
                </p>
                {auth.isForgotPasswordFailed && (
                  <p className={classes.authBody_errorMessage}>
                    No Account found for that email address.
                  </p>
                )}
                <form onSubmit={forgotPasswordHandler}>
                  <input
                    type="email"
                    className={`${classes.inputContainer_input} ${classes.inputContainer_input__forgotPassword}`}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <Button
                    disabled={disable}
                    type="submit"
                    text={auth.loading ? "Please wait..." : "Send"}
                  />
                </form>
              </>
            )}
          </div>
        </div>
      )}
      </>
  );
};

export default withRouter(SignIn);
