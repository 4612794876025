import React from "react";
import classes from "./styles.module.scss";

const PopUp = ({ children, title, onClose }) => {

  return (
    <div className={classes.popupWrapper}>
      <div className={classes.popup}>
        <div
          onClick={onClose}
          className={classes.popupClose}
        >
          &times;
        </div>
        <h2 className={classes.popup_header}>
          {title}
        </h2>

        {children}

      </div>
    </div>
  );
};

export default PopUp;
