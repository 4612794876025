import { getHeaders } from "../../../utils";
import axios from "../../../utils/axios";
import { showErrorMessage } from "../../../utils/toast";


export const loadUserDailySavedAnswersStatus = (setState, start_date, end_date, user_id) => {
  axios
    .get(`/question-answer/list/daily-saved-answers-status/?start_date=${start_date}&end_date=${end_date}&user_id=${user_id}`, { headers: getHeaders() })
    .then((res) => {
      setState(res.data);
    })
    .catch((error) => {
      showErrorMessage("Couldn't get saved answers. Please try again later!");
    });
};


export const loadSavedAnswers = (questionType, setState, userId, date) => {
  axios
    .get(`/question-answer/list/saved-answers/?question_type=${questionType}&date=${date}&user_id=${userId}`, { headers: getHeaders() })
    .then((res) => {
      setState(res.data);
    })
    .catch((error) => {
      showErrorMessage("Couldn't get saved answers. Please try again later!");
    });
};


export const loadTestResults = (userId, setState, setLoading) => {
  setLoading(true)
  axios
    .get(`/hormone/test-results/?user_id=${userId}`, { headers: getHeaders() })
    .then((res) => {
      setState(res.data);
      setLoading(false)
    })
    .catch((error) => {
      showErrorMessage("Couldn't get test results. Please try again later!");
      setLoading(false)
    });
}


export const loadNuggets = (setState, setLoading) => {
  setLoading(true)
  axios
    .get(`/hormone/hormone-result-nuggets/`, { headers: getHeaders() })
    .then((res) => {
      setState(res.data);
      setLoading(false)
    })
    .catch((error) => {
      showErrorMessage("Couldn't get result nuggets. Please try again later!");
      setLoading(false)
    });
}
