import React from 'react'
import styles from './styles.module.scss'


const CheckboxInput = ({ label, value, name, checked, onChange }) => {

  return (
    <label className={styles.checkboxContainer}>
      <input
        value={value}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        name={name}
      />
      <div className={styles.checkboxCheckmark}></div>
      {label}
    </label>

  )

}


export default CheckboxInput