import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import { loadUserInfo } from './store/actions/authActions';
import { loadNotifications } from "./store/actions/notificationActions";
import 'react-phone-number-input/style.css'
import "./styles/style.scss";


const userToken = localStorage.getItem(process.env.REACT_APP_USER_TOKEN_KEY_NAME);
if (userToken) {
	store.dispatch(loadUserInfo());
	store.dispatch(loadNotifications());
}

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
