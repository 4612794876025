import React, { useState } from 'react'
import Button from '../../../components/FormElements/Button'
import { getHeaders } from '../../../utils'
import classes from '../styles.module.scss'
import axios from '../../../utils/axios'
import InputField from '../../../components/FormElements/InputField'
import SuccessMessage from '../../../components/FormElements/SuccessMessage'


const CreatePatient = ({ onClose }) => {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [date_of_birth, setDateOfBirth] = useState("")
  const [height, setHeight] = useState("")
  const [ethnicity, setEthnicity] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("")
  const [postal_address, setPostalAddress] = useState("")

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const onCreateClick = (e) => {
    e.preventDefault()
    setErrors({}) // remove old errors

    const data = {
      name: name,
      email: email,
      mobile: mobile,
      date_of_birth: date_of_birth,
      city: city,
      country: country,
      ethnicity: ethnicity,
      postal_address: postal_address,
    }

    setLoading(true)
    axios.post('/auth/patients/', data, { headers: getHeaders() })
      .then(({ data }) => {
        setLoading(false)
        setSuccess(true)
      })
      .catch(error => {
        setErrors(error.response?.data || {})
        setLoading(false)
      })
  }

  return (
    <div className={classes.mainContent_formContainer}>
      <form>
        <h2 style={{ textAlign: 'center', marginTop: '0px' }}>Create Patient</h2>

        <InputField
          type="email"
          label={"Email"}
          value={email}
          onChange={setEmail}
          error={errors.email}
        />

        <InputField
          type="text"
          label={"Full name"}
          value={name}
          onChange={setName}
          error={errors.name}
        />

        <div className={classes.column}>
          <InputField
            type="date"
            label={"Date of birth"}
            value={date_of_birth}
            onChange={setDateOfBirth}
            error={errors.date_of_birth}
          />

          <InputField
            type="text"
            label={"Height"}
            value={height}
            onChange={setHeight}
            error={errors.height}
          />
        </div>

        <div className={classes.column}>
          <InputField
            type="text"
            label={"Mobile"}
            value={mobile}
            onChange={setMobile}
            error={errors.mobile}
          />

          <InputField
            type="text"
            label={"Ethnicity"}
            value={ethnicity}
            onChange={setEthnicity}
            error={errors.ethnicity}
          />
        </div>

        <InputField
          label={"Postal Address"}
          value={postal_address}
          onChange={setPostalAddress}
          error={errors.postal_address}
        />

        <div className={classes.column}>
          <InputField
            type="text"
            label={"City"}
            value={city}
            onChange={setCity}
            error={errors.city}
          />

          <InputField
            type="text"
            label={"Country"}
            value={country}
            onChange={setCountry}
            error={errors.country}
          />
        </div>



        <Button
          type={'submit'}
          text={loading ? "Please wait..." : "Create"}
          inlineStyles={{ marginTop: '20px', marginRight: "10px" }}
          onClick={onCreateClick}
          disabled={loading}
        />

        <Button
          text={"Close"}
          onClick={onClose}
          inlineStyles={{ marginTop: '20px' }}
        />

        {success && <SuccessMessage message="Clinician created successfully!" />}

      </form>
    </div>
  )

}

export default CreatePatient
