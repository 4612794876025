import React from 'react';
import styles from './styles.module.scss';

const Nugget = ({ data, onClick }) => {
  return (
    <div className={styles.nugget} onClick={() => onClick && onClick()}>
      <h2 className={styles.nugget_title}>{data.field_label}</h2>
      <p className={styles.nugget_content} style={{ color: data.color }}>{data.value}</p>
    </div>
  );
};

export default Nugget;
