import { getHeaders } from "../../../utils";
import axios from "../../../utils/axios";
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast";

export const loadQuestions = (questionType, setState) => {
  axios
    .get(`/question-answer/list/questions/?question_type=${questionType}`, { headers: getHeaders() })
    .then((res) => {
      setState(res.data);
    })
    .catch((error) => {
      showErrorMessage("Couldn't load questions. Please try again later!");
    });
};

export const loadSavedAnswers = (questionType, setState, date, setLoading) => {
  setLoading && setLoading(true)
  axios
    .get(`/question-answer/list/saved-answers/?question_type=${questionType}&date=${date}`, { headers: getHeaders() })
    .then((res) => {
      const convertedData = {};

      res.data.forEach((item) => {
        convertedData[item.question.id] = item.answer;
      });
      setState(oldData => ({...oldData, ...convertedData}));
      setLoading && setLoading(false)
    })
    .catch((error) => {
      showErrorMessage("Couldn't get saved answers. Please try again later!");
      setLoading && setLoading(false)
    });
};

export const loadDailySavedAnswersStatus = (setState, start_date, end_date) => {
  axios
    .get(`/question-answer/list/daily-saved-answers-status/?start_date=${start_date}&end_date=${end_date}`, { headers: getHeaders() })
    .then((res) => {
      setState(res.data);
    })
    .catch((error) => {
      showErrorMessage("Couldn't get saved answers. Please try again later!");
    });
};

export const saveAnswers = (answers, setCurrentStep, setLoading, setAnswers, date) => {
  setLoading(true)

  // not all items are answers, because we have a key called saved
  // which reset to false on every new step
  // in the request payload, we have to remove this
  const filteredData = Object.entries(answers).filter(([question, answer]) => question !== 'saved')

  const convertedData = filteredData.map(([question, answer]) => ({
    date: date,
    question: question,
    answer: answer,
  }));
  axios
    .post("/question-answer/save-answers/", convertedData, {
      headers: getHeaders(),
    })
    .then((res) => {
      setLoading(false)
      showSuccessMessage("Data saved successfully!");

      // change saved = true
      setAnswers && setAnswers(existingData => ({...existingData, saved: true}))
      // if last step, then no need to go next page
      setCurrentStep && setCurrentStep((currentStep) => currentStep + 1);
    })
    .catch((error) => {
      setLoading(false)
      showErrorMessage("Couldn't save the data, please try again later!");
    });
};


export const loadChartData = (setState, setLoading) => {
  setLoading(true)
  axios
    .post(`/hormone/hormone-result-chart/`, {}, { headers: getHeaders() })
    .then((res) => {
      setState(res.data);
      setLoading(false)
    })
    .catch((error) => {
      showErrorMessage("Couldn't get chart data. Please try again later!");
      setLoading(false)
    });
}
