import React from 'react'
import styles from './styles.module.scss';


const AnswerTable = ({ data }) => {
  const midpoint = Math.ceil(data.length / 2);
  const firstHalf = data.slice(0, midpoint);
  const secondHalf = data.slice(midpoint);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableColumn}>
        <div className={styles.heading}>
          <div>Question</div>
          <div>Answer</div>
        </div>
        {firstHalf.map((entry) => (
          <div className={styles.tableRow} key={entry.id}>
            <div className={styles.question}>{entry.question.question}</div>
            <div className={styles.answer}>{entry.answer}</div>
          </div>
        ))}
      </div>
      <div className={styles.tableColumn}>
        <div className={styles.heading}>
          <div>Question</div>
          <div>Answer</div>
        </div>
        {secondHalf.map((entry) => (
          <div className={styles.tableRow} key={entry.id}>
            <div className={styles.question}>{entry.question.question}</div>
            <div className={styles.answer}>{entry.answer}</div>
          </div>
        ))}
      </div>
    </div>
  )

}


export default AnswerTable