import React, { useState } from 'react'
import Navbar from '../../../components/layouts/Navbar'
import { setPageTitle } from '../../../utils'
import classes from '../styles.module.scss'
import CreateClinician from './CreateClinician'
import ClinicianTable from './ClinicianTable'

const AdminDashboardPage = () => {

  setPageTitle("Administrator Dashboard")
  const [createClinician, setCreateClinician] = useState(false)

  return (
    <div className={classes.dashboardContainer}>
      <Navbar />
      <div className={classes.mainContent}>
        {createClinician ? (
          <CreateClinician onClose={() => setCreateClinician(false)} />
        ) : (
          <ClinicianTable onCreate={() => setCreateClinician(true)} />
        )}
      </div>
    </div>
  )

}


export default AdminDashboardPage

