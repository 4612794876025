export const ROLES = {
  "COMPANY_OWNER": "Owner",
  "COMPANY_ADMIN": "Admin",
  "USER": "User",
}


export const isAdministrator = (userData) => {
  return ["ADMINISTRATOR"].includes(userData.user_type)
}

export const isClinician = (userData) => {
  return userData.user_type === "CLINICIAN"
}

export const isPatient = (userData) => {
  return userData.user_type === "PATIENT"
}
