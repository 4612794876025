import React from "react";
import classes from "./styles.module.scss";
import { setPageTitle } from "../../utils";
import { useSelector } from "react-redux";
import Button from "../../components/FormElements/Button";

const AccessDenied = ({ history }) => {
  setPageTitle("Access denied");

  const isNightMode = useSelector((state) => state?.theme?.nightMode);

  return (
    <div
      className={`${classes.container} ${
        isNightMode && classes.container_nightMode
      }`}
    >
      <div className={classes.container_box}>
        <h1>403</h1>
        <p>
          You're not allowed in this page!
        </p>
        <Button text={"Go Home"} onClick={() => history.push("/")} />
      </div>
    </div>
  );
};

export default AccessDenied;
