import React, { useEffect, useState } from 'react'
import { loadSavedAnswers } from './api-service'
import moment from 'moment'
import AnswerTable from './AnswerTable/AnswerTable'

const Answers = ({ date, userId }) => {
  const [answers, setAnswers] = useState([])

  useEffect(() => {
    loadSavedAnswers("Daily Question", setAnswers, userId, moment(date).format('YYYY-MM-DD'))

    // eslint-disable-next-line
  }, [date])

  return (
    <AnswerTable data={answers} />
  )

}


export default Answers