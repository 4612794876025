import React, { useEffect, useState } from 'react'
import { loadSavedAnswers, saveAnswers } from '../api-service'
import styles from './styles.module.scss'
import moment from 'moment'
import Question from '../../../../components/Question/Question'
import Button from '../../../../components/FormElements/Button'


const Questions = ({ date, questions, onClose }) => {

  const [savedAnswers, setSavedAnswers] = useState({})
  const [loading, setLoading] = useState(false)
  
  const onChange = (e) => {
    setSavedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [e.target.name]: e.target.value,
    }))
  };

  const handleSave = () => {
    saveAnswers(savedAnswers, null, setLoading, null, moment(date).format("YYYY-MM-DD"))
  }

  useEffect(() => {
    loadSavedAnswers("Daily Question", setSavedAnswers, moment(date).format('YYYY-MM-DD'))

    // eslint-disable-next-line
  }, [date])

  return (
    <div className={styles.questionSectionWrapper}>
      <div className={styles.content}>
        {questions.map(question => (
          <Question
            key={question.id}
            answers={savedAnswers}
            onChange={onChange}
            question={question}
          />
        ))}
      </div>
      <div className={styles.footer}>
        <div className={styles.controls}>
          <Button
            disabled={loading}
            onClick={handleSave}
            text={loading ? "Saving..." : "Save Changes"}
          />
          <Button
            onClick={onClose}
            text={"Close"}
          />
        </div>
      </div>
    </div>
  )

}


export default Questions