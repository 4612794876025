import React, { useEffect, useState } from "react";
import { changePassword } from '../../store/actions/authActions';

import classes from "./styles.module.scss";
import { useSelector, useDispatch } from 'react-redux'
import { CHANGE_PASSWORD_ERRORS } from "../../store/actions/actionTypes";
import Button from "../../components/FormElements/Button";
import InputField from "../../components/FormElements/InputField";

const ChangePassword = () => {

  const dispatch = useDispatch();

  const [old_password, setOldPassword] = useState('')
  const [new_password1, setNewPassword1] = useState('')
  const [new_password2, setNewPassword2] = useState('')

  const auth = useSelector((state) => state.auth);

  const submitHandler = e => {
    e.preventDefault()
    dispatch(changePassword({old_password, new_password1, new_password2}))
  }

  useEffect(() => {
    dispatch({type: CHANGE_PASSWORD_ERRORS, payload: {}})
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.setting_connectForm}>
      <h2 className={classes.setting_connectForm__header}>
        Change Password
      </h2>
      <form onSubmit={submitHandler}>
        <InputField
          type="password"
          label="Current Password"
          value={old_password}
          onChange={setOldPassword}
          error={auth.changePassword.errors.old_password}
        />
        <InputField
          type="password"
          label="New Password"
          value={new_password1}
          onChange={setNewPassword1}
          error={auth.changePassword.errors.new_password1}
        />
        <InputField
          type="password"
          label="Confirm Password"
          value={new_password2}
          onChange={setNewPassword2}
          error={auth.changePassword.errors.new_password2}
        />

        {auth.changePassword.success && (
          <div className={classes.inputContainer}>
            <div className={classes.inputContainer_successMessage}>
              Password changed successfully!
            </div>
          </div>
        )}

        <Button
          disabled={auth.loading}
          text={auth.loading ? "Please wait..." : "Change Password"}
          type='submit'
        />
      </form>
    </div>
  )

}


export default ChangePassword
