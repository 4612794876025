import React, { useEffect, useState } from 'react'
import Button from '../../components/FormElements/Button'
import Navbar from '../../components/layouts/Navbar'
import { setPageTitle } from '../../utils'
import styles from './authStyles.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { register } from '../../store/actions/authActions'
import ErrorMessage from '../../components/helpers/ErrorMessage'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";


const RegistrationPage = () => {
  setPageTitle("Create account");
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [isRegistered, setIsRegistered] = useState(false)

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  const submitHandler = (event) => {
    event.preventDefault();

    const registerData = {
      email,
      name,
      date_of_birth: null
    };
    dispatch(register(registerData, setIsRegistered));
  };

  useEffect(() => {
    if (!!auth.registerErrors.non_field_errors) {
      setMessage(auth.registerErrors.non_field_errors[0]);
    }
  }, [auth]);

  return (
    <>
      <Navbar />
      {isRegistered ? (
        <div className={styles.successCard}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={styles.image}
          />
          <p className={styles.message}>
            Thank you for registering with us! To complete your registration and ensure the security of your account,
            please check your email for instructions on how to set your password.
          </p>
        </div>
      ) : (
        <div class={styles.signupContainer}>
          <h1>How does it work?</h1>
          <ol>
            <li>Sign up below.</li>
            <li>Create a profile.</li>
            <li>Receive your at-home blood collection kit.
              <p>Get your kit within 3-5 days of ordering. Your kit will test:</p>
              <ul>
                <li>Follicle Stimulating Hormone (FSH)</li>
                <li>Luteinising Hormone (LH)</li>
                <li>Total Testosterone</li>
                <li>DHEA Sulfate</li>
                <li>Oestradiol</li>
                <li>Sexual Hormone Binding Globulin (SHBG)</li>
                <li>Vitamin D</li>
                <li>Progesterone - Day 21</li>
              </ul>
            </li>
            <p></p>
            <li>Get your results and personalised doctor-written report
              <p>10 days later, access your hormone results with a detailed report from one of our private gynaecologists with suggestions for your next steps.</p>
            </li>
            <li>Receive daily suggestions via our calendar.</li>
          </ol>
          <form>
            <label>Email:</label>
            <input
              type="email"
              required
              onChange={e => setEmail(e.target.value)}
            />
            {auth.registerErrors.email && (
              <ErrorMessage error={auth.registerErrors.email} />
            )}
            <label>Full Name:</label>
            <input
              type="name"
              required
              onChange={e => setName(e.target.value)}
            />
            {auth.registerErrors.name && (
              <ErrorMessage error={auth.registerErrors.name} />
            )}
            <Button
              inlineStyles={{ marginTop: "20px" }}
              type="submit"
              disabled={auth.loading}
              text={auth.loading ? "Please wait..." : "Sign Up"}
              onClick={submitHandler}
            />
          </form>
          {message && <p style={{ color: "red" }}>{message}</p>}
        </div>
      )}
    </>
  )

}


export default RegistrationPage