import React, { useEffect, useState } from 'react'
import { loadSavedAnswers } from '../api-service'
import styles from './styles.module.scss';

const InitialAnswers = ({ userId }) => {
  const [answers, setAnswers] = useState([])

  useEffect(() => {
    loadSavedAnswers("Initial Question", setAnswers, userId)

    // eslint-disable-next-line
  }, [userId])


  // Group the data by categories
  const groupedData = answers.reduce((acc, item) => {
    const category = item.question.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  // Define the order of categories
  const categoryOrder = [
    "Personal Data",
    "Medical",
    "Section A",
    "Section B",
    "Section C",
    "Section D",
    "Section E",
    "Section F",
    "Section G",
  ];

  return (
    <div className={styles['questionsData']}>
      {categoryOrder.map(category => (
        <div key={category} className={styles.category}>
          <h2>{category}</h2>
          <table>
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {groupedData[category]?.map(item => (
                <tr key={item.id}>
                  <td>{item.question.question}</td>
                  <td>{item.answer}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  )

}


export default InitialAnswers