import React, { useState } from 'react'
import styles from './styles.module.scss'
import Question from '../../../../components/Question/Question'
import { saveAnswers } from '../api-service'
import Button from '../../../../components/FormElements/Button'


const Section = ({ setAnswers, answers, currentStep, setCurrentStep, questions, totalNumQuestions, sectionTitle }) => {

  const [loading, setLoading] = useState(false)
  
  const onChange = (e) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      saved: false, // on value change, change saved to false
      [e.target.name]: e.target.value,
    }))
  };

  const handlePreviousPage = () => {
    setCurrentStep(currentStep => currentStep - 1)
  }
  const handleSave = () => {
    saveAnswers(answers, setCurrentStep, setLoading, setAnswers)
  }

  const isNextDisabled = () => {
    return Object.keys(answers).length - 1 < totalNumQuestions // - 1 because of 'saved' key
  }

  return (
    <div className={styles.questionSectionWrapper}>

      <div className={styles.header}>
        <h1 className={styles.title}>{sectionTitle}</h1>
        <div className={styles.controls}>
          <Button onClick={handlePreviousPage} disabled={currentStep === 1} text={"Prev"} />
          <Button onClick={handleSave} disabled={isNextDisabled() || loading} text={loading ? "Saving..." : "Save & Continue"} />
        </div>
      </div>

      <div className={styles.content}>
        {questions.map(question => (
          <Question
            key={question.id}
            answers={answers}
            onChange={onChange}
            question={question}
          />
        ))}
      </div>
    </div>
  )

}


export default Section