import React from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";
import AdminRoute from "./AdminRoute";
import ClinicianRoute from "./ClinicianRoute";
import PatientRoute from "./PatientRoute";

import NotFound from "../pages/Others/NotFound";
import AccessDenied from "../pages/Others/AccessDenied";
import SettingsPage from "../pages/Settings/Settings";

import * as URLS from "./urls";

import HomePage from "../pages/HomePage/HomePage";
import LoginPage from "../pages/auth/LoginPage";
import RegistrationPage from "../pages/auth/RegistrationPage";
import ResetPassword from "../pages/auth/ResetPassword";
import DashboardPage from '../pages/DashboardPage/DashboardPage'
import PatientDashboardPage from "../pages/DashboardPage/PatientDashboard/PatientDashboardPage";
import ClinicianDashboardPage from "../pages/DashboardPage/ClinicianDashboard/ClinicianDashboardPage";
import AdminDashboardPage from "../pages/DashboardPage/AdministratorDashboard/AdminDashboardPage";
import SetPassword from "../pages/auth/SetPassword";
import NotificationPage from "../pages/NotificationPage/NotificationPage";


const Routes = () => {

  return (
    <Switch>
      <Route
        path={URLS.HOME_PAGE}
        exact
        // component={HomePage}
        component={LoginPage}
      />
      <PrivateRoute path={URLS.SETTINGS_PAGE} exact component={SettingsPage} />
      <PrivateRoute path={URLS.DASHBOARD_PAGE} exact component={DashboardPage} />
      <PrivateRoute path={URLS.NOTIFICATION_PAGE} exact component={NotificationPage} />
      <AdminRoute path={URLS.ADMIN_DASHBOARD_PAGE} exact component={AdminDashboardPage} />
      <ClinicianRoute path={URLS.CLINICIAN_DASHBOARD_PAGE} exact component={ClinicianDashboardPage} />
      <PatientRoute path={URLS.PATIENT_DASHBOARD_PAGE} exact component={PatientDashboardPage} />

      {/* auth routes */}
      <GuestRoute
        exact
        path={URLS.LOGIN_PAGE}
        component={LoginPage}
      />
      <GuestRoute
        exact
        path={URLS.REGISTRATION_PAGE}
        component={RegistrationPage}
      />
      <GuestRoute
        exact
        path={URLS.PASSWORD_RESET_PAGE}
        component={ResetPassword}
      />
      <GuestRoute
        exact
        path={URLS.SET_PASSWORD_PAGE}
        component={SetPassword}
      />
      <Route path={URLS.ACCESS_DENIED_PAGE} component={AccessDenied} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
