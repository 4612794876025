import React from 'react'
import styles from './styles.module.scss'


const RadioInput = ({ label, value, name, checked, onChange }) => {

  return (
    <label className={styles.radioContainer}>
      <input
        value={value}
        type="radio"
        checked={checked}
        onChange={onChange}
        name={name}
      />
      <div className={styles.radioCheckmark}></div>
      {label}
    </label>
  )

}


export default RadioInput