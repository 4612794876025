import React from 'react'
import ErrorMessage from '../../../../components/helpers/ErrorMessage';
import styles from './styles.module.scss'


const HormoneResult = ({ data }) => {


  const getTotalResults = (dateData) => {
    return dateData.tests.reduce((testTotal, test) => {
      return testTotal + test.results.length;
    }, 0);
  };


  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th rowSpan="2">Date</th>
            <th rowSpan="2">Test Name</th>
            <th colSpan="2" className={styles.superHeading}>Results</th>
          </tr>
          <tr>
            <th>Hormone</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {data ? data.map((dateData, dateIndex) => (
            dateData.tests.map((test, index) => (
              <React.Fragment key={`${dateData.date}-${test.name}-${index}`}>
                {test.results.map((result, resultIndex) => (
                  <tr
                    key={`${dateData.date}-${test.name}-${index}-${result.hormone}-${resultIndex}`}
                    className={dateIndex % 2 === 0 ? styles.evenRow : styles.oddRow}
                  >
                    {resultIndex === 0 && (
                      <React.Fragment>
                        {index === 0 && (
                          <td rowSpan={getTotalResults(dateData)}>{dateData.date}</td>
                        )}
                        <td rowSpan={test.results.length}>{test.name}</td>
                      </React.Fragment>
                    )}
                    <td>{result.hormone}</td>
                    <td>{result.value}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))
          )) : (
            <tr>
              <td colSpan={4}>
                <ErrorMessage error={"No data to display"} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )

}


export default HormoneResult