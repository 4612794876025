import React, { useEffect, useState } from 'react'
import classes from '../styles.module.scss'
import { setPageTitle } from '../../../utils'
import Navbar from '../../../components/layouts/Navbar'
import InitialQuestions from './InitialQuestion/InitialQuestions'
import DailyQuestions from './DailyQuestion/DailyQuestions'
import { loadChartData, loadQuestions, loadSavedAnswers } from './api-service'
import CommenLoader from '../../../components/CommenLoader'
import { loadNuggets, loadTestResults } from '../ClinicianDashboard/api-service'
import { useSelector } from 'react-redux'
import HormoneResult from '../ClinicianDashboard/HormoneResult/HormoneResult'
import Nugget from '../../../components/Nugget/Nugget'
import { LineChart } from '../../../components/Charts/LineChart'


const PatientDashboardPage = () => {

  setPageTitle("Patient Dashboard")

  const [initialQuestions, setInitialQuestions] = useState([])
  const [initialSavedAnswers, setInitialSavedAnswers] = useState({ 'saved': true })
  const [isAllInitialQuestionSaved, setIsAllInitialQuestionSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [testResults, setTestResults] = useState([])
  const [loadingTestResults, setLoadingTestResults] = useState(false)
  const [loadingNugget, setLoadingNugget] = useState(false)
  const [nuggets, setNuggets] = useState([])
  const [loadingChart, setLoadingChart] = useState(false)
  const [chartData, setChartData] = useState({"labels": [], "datasets": []})
  const [selectedHormone, setSelecteHormone] = useState(null)
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    // if no question, or initial questions are not saved
    // then no need to check, just return
    if ((initialQuestions.length > 0) & (initialSavedAnswers.saved)) {
      if (initialQuestions.length === Object.keys(initialSavedAnswers).length - 1) { // - 1 because of an extra 'saved' key
        setIsAllInitialQuestionSaved(true)
      }
    }

  }, [initialQuestions, initialSavedAnswers])

  useEffect(() => {
    // load initial questions and answers to see
    // if user has submitted all initial questions
    loadQuestions("Initial Question", setInitialQuestions)
    loadSavedAnswers("Initial Question", setInitialSavedAnswers, null, setLoading)
    loadNuggets(setNuggets, setLoadingNugget)
    loadChartData(setChartData, setLoadingChart)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (auth.user.id) {
      loadTestResults(auth.user.id, setTestResults, setLoadingTestResults)
    }
  }, [auth.user.id])

  // get dataset
  const getDataset = (data) => {
    // if no hormone is selected, show all
    if (!selectedHormone) return data;
    return {
      ...data,
      datasets: data.datasets.filter(item => item["label"] === selectedHormone)
    }

  }

  return (
    <div className={classes.dashboardContainer}>
      <Navbar />
      <div className={classes.mainContent}>
        {loading ? (
          <CommenLoader />
        ) : (
          <>
            {isAllInitialQuestionSaved ? (
              <DailyQuestions />
            ) : (
              <InitialQuestions
                questions={initialQuestions}
                savedAnswers={initialSavedAnswers}
                setSavedAnswers={setInitialSavedAnswers}
              />
            )}

            {loadingNugget ? (
              <CommenLoader />
            ) : (
              <>
                <div className={classes.nuggetContainer}>
                  {nuggets.map(nugget => (
                    <Nugget onClick={() => setSelecteHormone(nugget.field_label)} key={nugget.field_name} data={nugget} />
                  ))}
                </div>

                <div className={classes.chartContainer}>
                  {loadingChart ? (
                    <CommenLoader />
                  ) : (
                    <LineChart data={getDataset(chartData)} />
                  )}
                </div>
              </>
            )}

            <h2 style={{ fontSize: "32px", textAlign: "center", marginTop: "25px" }}>Hormone Test Result</h2>
            {loadingTestResults ? (
              <CommenLoader />
            ) : (
              <HormoneResult data={testResults} />
            )}
          </>
        )}

      </div>
    </div>
  )

}


export default PatientDashboardPage