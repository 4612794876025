export const HOME_PAGE = "/"
export const SETTINGS_PAGE = "/settings"
export const LOGIN_PAGE = "/login";
export const REGISTRATION_PAGE = "/registration";
export const PASSWORD_RESET_PAGE = "/forget-password/reset/:uid/:token";
export const ACCESS_DENIED_PAGE = "/access/denied";
export const DASHBOARD_PAGE = '/dashboard'
export const NOTIFICATION_PAGE = '/notification'
export const ADMIN_DASHBOARD_PAGE = '/admin/dashboard'
export const CLINICIAN_DASHBOARD_PAGE = '/clinician/dashboard'
export const PATIENT_DASHBOARD_PAGE = '/patient/dashboard'
export const SET_PASSWORD_PAGE = '/set-password/:uid/:token'