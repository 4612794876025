import React from 'react'
import styles from './styles.module.scss'


const Button = ({ disabled, type, loading, text, onClick, inlineStyles }) => {

  return (
    <button
      disabled={disabled}
      className={styles.form_button}
      style={inlineStyles}
      type={type || "button"}
      onClick={(e) => onClick && onClick(e)}
    >
      {loading ? "Please wait..." : text}
    </button>
  );

}


export default Button