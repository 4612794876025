import React from "react";
import Routes from "./Routes/Routes";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <>
      <ToastContainer />
      <Routes />
    </>
  )
}

export default App;
