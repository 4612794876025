import React, { useState } from "react";
import classes from "./styles.module.scss";
import ChangePassword from "./ChangePassword";
import UpdateProfile from "./UpdateProfile";
import Navbar from "../../components/layouts/Navbar";
import { setPageTitle } from "../../utils";

const SettingsPage = () => {
  const [component, setComponent] = useState("profile");

  setPageTitle("Settings")

  return (
    <>
      <Navbar />
      <div
        className={classes.setting}
      >
        <div className={classes.setting_right}>
          <div className={classes.listWrap}>

            <span
              className={`${classes.listWrap_item} ${component === "profile" && classes.listWrap_item__active
                }`}
              onClick={() => setComponent("profile")}
            >
              Profile
            </span>

            <span
              className={`${classes.listWrap_item} ${component === "changePassword" && classes.listWrap_item__active
                }`}
              onClick={() => setComponent("changePassword")}
            >
              Password
            </span>
          </div>
          {component === "profile" && <UpdateProfile />}
          {component === "changePassword" && <ChangePassword />}
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
