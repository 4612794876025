import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { LOGIN_PAGE, ACCESS_DENIED_PAGE } from './urls';
import { isAdministrator } from '../utils/authUtils';


const AdminRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {

        return (
          <>
            {auth.isAuthenticated ? (
              <>
                {(isAdministrator(auth.user) || auth.loading) ? (
                  <Component {...props} />
                ) : (
                  <Redirect
                    to={{
                      pathname: ACCESS_DENIED_PAGE,
                    }}
                  />
                )}
              </>
            ) : (
              <Redirect
                to={{
                  pathname: LOGIN_PAGE,
                }}
              />
            )}
          </>
        )
      }}
    />
  );

}


export default AdminRoute
