import * as Types from "../actions/actionTypes";

const initialState = {
  total_unread: 0,
  notifications: []
};

function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case Types.NOTIFICATION_LOADED: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: return state;
  }
}

export default notificationReducer;
