import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './styles.module.scss';
import CommenLoader from '../../components/CommenLoader';
import TopNavbar from '../../components/layouts/Navbar';
import { loadNotifications, markNotificationAsRead } from '../../store/actions/notificationActions';
import { Container } from 'react-bootstrap'
import moment from 'moment'


const NotificationPage = () => {
  const dispatch = useDispatch()
  const notification = useSelector(state => state.notification)

  useEffect(() => {
    dispatch(loadNotifications())
    // eslint-disable-next-line
  }, [])

  const formatDate = (date) => {
    const now = moment();
    const targetDate = moment(date);

    // Calculate the difference in days
    const daysDiff = now.diff(targetDate, 'days');

    console.log(daysDiff)

    // Format the date based on the difference
    if (daysDiff > -3 && daysDiff < 3) {
      return `${targetDate.fromNow()}`;
    } else {
      return `${targetDate.format('Do MMMM, YYYY')}`;
    }
  }

  const markAsRead = (notification) => {
    if (!notification.is_read) {
      dispatch(markNotificationAsRead(notification.id))
    }
  }

  return (
    <>
      <TopNavbar />

      <Container>
        {notification.loading ? (
          <CommenLoader />
        ) : (
          <div className={classes.notificationWrapper}>
            <h2 style={{ fontSize: "22px" }}>Notifications &nbsp;
              <small style={{ fontSize: "16px", fontWeight: "normal" }}>(Click on the notification to mark as read.)</small>
            </h2>
            {notification.notifications.map(notification => (
              <div
                className={`${classes.notificationContainer} ${!notification.is_read && classes.unread}`}
                onClick={() => markAsRead(notification)}
              >
                <div className={classes.date}>{formatDate(notification.created_at)}</div>
                <div className={classes.content}>
                  <h3>{notification.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: notification.body }} />
                </div>
              </div>
            ))}
          </div>
        )}
      </Container>
    </>
  )

}


export default NotificationPage