import React from "react";

const ErrorMessage = ({ isChart, minHeight, error }) => {
  return (
    <div
      style={{
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: minHeight ? minHeight : `${isChart ? "355px" : null}`,
      }}
    >
      <p
        style={{
          color: "red",
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        {error}
      </p>
    </div>
  );
};

export default ErrorMessage;
