import React from 'react'
import { useState, useEffect } from 'react';

const Timer = (props) => {
  const { initialSeconds = 0, onFinish, message } = props;
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval)
        onFinish()
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      {message(seconds)}
    </>
  )
}

export default Timer;