import React from 'react'
import CheckboxGroup from '../helpers/CheckboxInput/CheckboxGroup'
import RadioGroup from '../helpers/RadioInput/RadioGroup'
import classes from './styles.module.scss'


const Question = ({ question, answers, onChange }) => {

  return (
    <div className={classes.questionRow}>
      <div className={classes.questionRow_questionText}>
        {question.question}
      </div>
      {question.answer_type === "Input" ? (
        <>
          {question.answer_field === 'LARGETEXT_FIELD' ? (
            <textarea
              className={classes.inputField}
              value={answers[question.id] || ''}
              name={question.id}
              onChange={onChange}
            />
          ) : (
            <input
              className={classes.inputField}
              value={answers[question.id] || ''}
              type={question.answer_field === 'DATE_FIELD' ? "date" : question.answer_field === 'EMAIL_FIELD' ? "email" : "text"}
              name={question.id}
              onChange={onChange}
            />
          )}
        </>
      ) : (
        <div className={classes.questionRow_questionOptions}>
          {question.answer_field === "RADIO_FIELD" ? (
            <RadioGroup
              question={question}
              onChange={onChange}
              answers={answers}
            />
          ) : (
            <CheckboxGroup
              question={question}
              onChange={onChange}
              answers={answers}
            />
          )}
        </div>
      )}

    </div>
  )

}


export default Question