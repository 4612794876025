import React, { useEffect, useState } from 'react'
import { createUUID, getHeaders } from '../../../utils'
import classes from '../styles.module.scss'
import axios from '../../../utils/axios'
import CommenLoader from '../../../components/CommenLoader'
import Button from '../../../components/FormElements/Button'
import moment from 'moment'
import ErrorMessage from '../../../components/FormElements/ErrorMessage'
import PatientDetails from './PatientDetails'
import { confirmAlert } from 'react-confirm-alert'
import { showErrorMessage, showSuccessMessage } from '../../../utils/toast'

const PatientTable = ({ onCreate }) => {

  const [patients, setPatients] = useState([])
  const [patientsLoading, setPatientsLoading] = useState(false)
  const [patientsLoadError, setPatientsLoadError] = useState(false)
  const [isPatientDetails, setIsPatientDetails] = useState(false)
  const [openedPatientData, setOpenedPatientData] = useState({})

  const loadPatients = () => {
    // load the patients accounts
    setPatientsLoading(true)

    axios.get('/auth/patients/', { headers: getHeaders() })
      .then(({ data }) => {
        setPatients(data)
        setPatientsLoading(false)
        setPatientsLoadError(false)
      })
      .catch(error => {
        setPatientsLoading(false)
        setPatientsLoadError(true)
      })
  }

  useEffect(() => {
    loadPatients()
  }, [])

  const onClickPatientDetails = (patientData) => {
    setIsPatientDetails(true)
    setOpenedPatientData(patientData)
  }

  const deletePatient = (patientId) => {
    confirmAlert({
      title: "Are you sure?",
      message: "This patient account will be deleted permanently and there's no going back. Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios.delete(`/auth/patient/${patientId}`, { headers: getHeaders() })
              .then(res => {
                showSuccessMessage(`Patient with id - ${patientId} was deleted!`)

                // load the patients data again.
                loadPatients()
              })
              .catch(error => {
                showErrorMessage()
              })
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  }


  return (
    <>
      {isPatientDetails ? (
        <PatientDetails data={openedPatientData} />
      ) : (
        <div>
          <Button
            text={"Create new Patient"}
            onClick={onCreate}
          />
          <div className={classes.mainContent_tableContainer}>
            <table className={classes.mainContent_table}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Last Submit Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {patientsLoading ? (
                  <tr><td colSpan={5}><CommenLoader height={120} size={50} /></td></tr>
                ) : (
                  <>
                    {patientsLoadError ? (
                      <tr><td colSpan={5}>
                        <ErrorMessage message={"Something went wrong! Please try again later!"} />
                        </td></tr>
                    ) : (
                      <>
                        {patients.length ? (
                          <>
                            {patients.map(patient => (
                              <tr key={createUUID()}>
                                <td>
                                  <div className={classes.patientDetailsLink} onClick={() => onClickPatientDetails(patient)}>
                                    {patient.id}
                                  </div>
                                </td>
                                <td>
                                  <div className={classes.patientDetailsLink} onClick={() => onClickPatientDetails(patient)}>
                                    {patient.name}
                                  </div>
                                </td>
                                <td>{patient.email}</td>
                                <td>{patient.last_submit_time ? moment(patient.last_submit_time).fromNow() : "-"}</td>
                                <td>
                                  <div className={classes.patientDetailsLink} onClick={() => deletePatient(patient.id)}>
                                    Delete
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr><td colSpan={6}><ErrorMessage message={"No patient found!"} /></td></tr>
                        )}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )

}


export default PatientTable

