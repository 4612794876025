import React, { useState } from "react";
import classes from "./authStyles.module.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../store/actions/authActions";
import { setPageTitle } from "../../utils";
import { HOME_PAGE, LOGIN_PAGE } from "../../Routes/urls";
import Timer from "../../components/helpers/Timer";
import Button from "../../components/FormElements/Button";
import TopNavbar from "../../components/layouts/Navbar";


const ResetPassword = (props) => {

  const dispatch = useDispatch();
  const [new_password1, setPassword1] = useState("");
  const [new_password2, setPassword2] = useState("");
  const [message, setMessage] = useState("");
  const { uid, token } = props.match.params;
  const auth = useSelector(state => state.auth);

  setPageTitle("Reset Password")

  const submitHandler = event => {
    event.preventDefault();
    if (new_password1 !== new_password2) {
      setMessage("Password didn't match");
      return false
    }
    else {
      setMessage("");
    }
    dispatch(resetPassword(token, uid, new_password1, new_password2));
  }

  return (
    <>
      <TopNavbar />
      <div className={classes.authContainer}>
        <div className={classes.authContainer_FormContainer}>
          <h2 className={classes.authContainer_FormContainer__header}>
            Reset Password
          </h2>
          <form onSubmit={submitHandler}>
            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                New Password
              </label>
              <input
                type="password"
                className={classes.inputContainer_input}
                required
                value={new_password1}
                onChange={e => setPassword1(e.target.value)}
              />
            </div>
            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Confirm Password
              </label>
              <input
                type="password"
                className={classes.inputContainer_input}
                required
                value={new_password2}
                onChange={e => setPassword2(e.target.value)}
              />
            </div>
            <Button disabled={auth.loading} type="submit" text={auth.loading ? "Please wait..." : "Submit"} />
          </form>


          {message ? (
            <p style={{ color: 'red' }}>{message}</p>
          ) : (
            <>
              {Object.keys(auth.resetPasswordErrors).length > 0 && (
                <p style={{ color: 'red' }}>
                  {auth.resetPasswordErrors.new_password2 ? "Password didn't match" : "Token invalid or expired"}
                </p>
              )}
            </>
          )}

          {auth.passwordResetted && (
            <>
              <p style={{ color: 'green', fontWeight: 600, fontSize: '14px', marginBottom: 0 }}>
                Password changed successfully!
              </p>
              <Timer
                onFinish={() => props.history.push("/login")}
                initialSeconds={5}
                message={(seconds) => (
                  <p style={{ color: "green", fontWeight: 600, fontSize: '14px', marginTop: 0, marginBottom: 0 }}>
                    {seconds === 0 ? "Redirecting..." : `You'll be redirected to login page in ${seconds} ${seconds > 1 ? "seconds" : "second"}...`}
                  </p>
                )}
              />
            </>
          )}

          <div className={classes.authContainer_linkText}>
            Back to{" "}
            <Link to={LOGIN_PAGE} className={classes.authContainer_link}>
              Homepage
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
