import axios from "../../utils/axios";
import { getHeaders } from "../../utils";
import * as Types from "./actionTypes";
import { showSuccessMessage } from "../../utils/toast";

export const loadNotifications = () => dispatch => {
  axios
    .get(`/notification/notifications/`, { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.NOTIFICATION_LOADED, payload: res.data });
    })
    .catch((error) => {});
};


export const markNotificationAsRead = (notificationId) => dispatch => {
  axios
    .post(`/notification/notifications/mark-read/${notificationId}/`, {}, { headers: getHeaders() })
    .then((res) => {
      showSuccessMessage(res.data?.message)
      dispatch(loadNotifications())
    })
    .catch((error) => {});
};

