import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";
import { useSelector, useDispatch } from 'react-redux'
import { updateProfile } from "../../store/actions/authActions";
import { PROFILE_UPDATE_ERRORS } from "../../store/actions/actionTypes";
import InputField from '../../components/FormElements/InputField'
import Button from "../../components/FormElements/Button";
import SuccessMessage from "../../components/FormElements/SuccessMessage";


const UpdateProfile = () => {

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  // states
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [date_of_birth, setDateOfBirth] = useState('')
  const [height, setHeight] = useState('')
  const [mobile, setMobile] = useState('')
  const [ethnicity, setEthnicity] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [postal_address, setPostalAddress] = useState('')

  const submitHandler = e => {
    e.preventDefault()
    if (auth.user.user_type === "PATIENT") {
      dispatch(updateProfile({
        email, name, date_of_birth, height, mobile,
        ethnicity, city, country, postal_address
      }))
    } else {
      dispatch(updateProfile({ email, name, mobile }))
    }
  }

  useEffect(() => {
    if (auth.user.email) setEmail(auth.user.email)
    if (auth.user.first_name) setName(auth.user.first_name + " " + auth.user.last_name)
    if (auth.user.date_of_birth) setDateOfBirth(auth.user.date_of_birth)
    if (auth.user.height) setHeight(auth.user.height)
    if (auth.user.mobile) setMobile(auth.user.mobile)
    if (auth.user.ethnicity) setEthnicity(auth.user.ethnicity)
    if (auth.user.city) setCity(auth.user.city)
    if (auth.user.country) setCountry(auth.user.country)
    if (auth.user.postal_address) setPostalAddress(auth.user.postal_address)

    // eslint-disable-next-line
  }, [auth.user])

  useEffect(() => {
    dispatch({ type: PROFILE_UPDATE_ERRORS, payload: {} })
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.setting_connectForm}>
      <h2 className={classes.setting_connectForm__header}>
        Update Profile
      </h2>
      <form onSubmit={submitHandler}>
        <InputField
          label="Email"
          type="email"
          value={email}
          onChange={setEmail}
          error={auth.updateProfile.errors.email}
        />
        <InputField
          label="Full Name"
          value={name}
          onChange={setName}
          error={auth.updateProfile.errors.name}
        />
        {auth.user.user_type === "PATIENT" && (
          <div className={classes.column}>
            <InputField
              type="date"
              label="Date of birth"
              value={date_of_birth}
              onChange={setDateOfBirth}
              error={auth.updateProfile.errors.date_of_birth}
            />

            <InputField
              label="Height"
              value={height}
              onChange={setHeight}
              error={auth.updateProfile.errors.height}
            />
          </div>
        )}

        <InputField
          label="Mobile"
          value={mobile}
          onChange={setMobile}
          error={auth.updateProfile.errors.mobile}
        />

        {auth.user.user_type === "PATIENT" && (
          <>
            <div className={classes.column}>
              <InputField
                type="text"
                label={"Ethnicity"}
                value={ethnicity}
                onChange={setEthnicity}
                error={auth.updateProfile.errors.ethnicity}
              />

              <InputField
                label={"City"}
                value={city}
                onChange={setCity}
                error={auth.updateProfile.errors.city}
              />
            </div>
            <div className={classes.column}>
              <InputField
                label={"Country"}
                value={country}
                onChange={setCountry}
                error={auth.updateProfile.errors.country}
              />

              <InputField
                label={"Postal Address"}
                value={postal_address}
                onChange={setPostalAddress}
                error={auth.updateProfile.errors.postal_address}
              />
            </div>
          </>
        )}

        {auth.updateProfile.success && <SuccessMessage message="Profile updated successfully!" />}

        <Button
          disabled={auth.loading}
          type='submit'
          text={auth.loading ? "Please wait..." : "Save Changes"}
        />
      </form>
    </div>
  )

}

export default UpdateProfile
