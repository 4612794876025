import React, { useEffect, useState } from 'react'
import Button from '../../../components/FormElements/Button'
import { getHeaders } from '../../../utils'
import classes from '../styles.module.scss'
import axios from '../../../utils/axios'
import InputField from '../../../components/FormElements/InputField'
import Select from 'react-select'
import ErrorMessage from '../../../components/FormElements/ErrorMessage'
import SuccessMessage from '../../../components/FormElements/SuccessMessage'


const CreateClinician = ({ onClose }) => {

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [clinic, setClinic] = useState(null)
  const [clinics, setClinics] = useState([])

  const onCreateClick = (e) => {
    e.preventDefault()
    setErrors({}) // remove old errors

    const data = {
      name: name,
      password_1: password,
      password_2: password,
      email: email,
      clinic: clinic
    }

    setLoading(true)
    axios.post('/auth/clinicians/', data, { headers: getHeaders() })
      .then(({ data }) => {
        setLoading(false)
        setSuccess(true)
      })
      .catch(error => {
        setErrors(error.response?.data || {})
        setLoading(false)
      })
  }

  useEffect(() => {
    // load clinics data, we need it in create clinician page
    axios.get('/auth/clinics/', { headers: getHeaders() })
      .then(({ data }) => {
        setClinics(data)
      })
      .catch(error => {
        setClinics([])
      })
  }, [])

  return (
    <div className={classes.mainContent_formContainer}>
      <form>
        <h2 style={{ textAlign: 'center', marginTop: '0px' }}>Create Clinician</h2>

        <InputField
          type="email"
          label={"Email"}
          value={email}
          onChange={setEmail}
          error={errors.email}
        />

        <InputField
          type="text"
          label={"Full name"}
          value={name}
          onChange={setName}
          error={errors.name}
        />

        <InputField
          type="text"
          label={"Password"}
          value={password}
          onChange={setPassword}
          error={errors.password_1}
        />

      <label style={{
        display: "inline-block",
        marginBottom: "10px",
        color: "#434343",
        fontSize: "15px"
      }}>
        {"Select a clinic"}
      </label>
        <Select
          options={clinics.map(c => ({ value: c.id, label: c.clinic_name }))}
          placeholder="Select a clinic"
          onChange={option => setClinic(option.value)}
        />
        {!!errors.clinic && (
          <ErrorMessage message={errors.clinic} />
        )}

        <Button
          type={'submit'}
          text={loading ? "Please wait..." : "Create"}
          inlineStyles={{ marginTop: '20px', marginRight: "10px" }}
          onClick={onCreateClick}
          disabled={loading}
        />

        <Button
          text={"Close"}
          onClick={onClose}
          inlineStyles={{ marginTop: '20px' }}
        />

        {success && <SuccessMessage message="Clinician created successfully!" />}

      </form>
    </div>
  )

}

export default CreateClinician
